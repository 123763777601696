import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";

const PrestationsLiveTemplate = function ({ data: { appSetting, page } }) {
  return (
    <Layout appSettingLocale={appSetting}>
      <Hero
        title={page.hero?.title ? page.hero.title : page.title}
        description={page.hero?.description && page.hero.description}
        background={
          page.hero?.background && page.hero.background.localFile.publicURL
        }
      />
      <section className="ttm-row-page">
        <Container>
          {parse(page.content.data.content)}
          <Row className="text-center mb-5 align-items-center">
            {page.logos &&
              page.logos.map(({ id, localFile }) => (
                <Col lg={4} className="my-4">
                  <GatsbyImage
                    key={`logos-${id}`}
                    image={localFile.childImageSharp.gatsbyImageData}
                    alt={localFile.name}
                  />
                </Col>
              ))}
          </Row>
          <Row className="text-center">
            {page.images &&
              page.images.map(({ id, localFile }) => (
                <Col lg={6} className="mb-4">
                  <GatsbyImage
                    key={`images-${id}`}
                    className="shadow"
                    image={localFile.childImageSharp.gatsbyImageData}
                    alt={localFile.name}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <GatsbyImage
        image={page.footer.localFile.childImageSharp.gatsbyImageData}
        alt={page.footer.localFile.name}
      />
    </Layout>
  );
};

PrestationsLiveTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PrestationsLiveTemplate;

export const Head = ({
  location,
  data: {
    page: { title, localizations },
  },
}) => (
  <Seo
    title={title}
    localizations={localizations.data}
    pathname={location.pathname}
  />
);

export const pageQuery = graphql`
  query ($id: String!, $locale: String!) {
    page: strapiPrestationsLive(id: { eq: $id }) {
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      title
      hero {
        title
        description
        background {
          name
          localFile {
            publicURL
          }
        }
      }
      content {
        data {
          content
        }
      }
      logos {
        id
        localFile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      images {
        id
        localFile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      footer {
        id
        localFile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    appSetting: strapiAppSetting(locale: { eq: $locale }) {
      headerNav {
        strapi_json_value {
          label
          url
          subMenu {
            label
            url
          }
        }
      }
      footerNav {
        strapi_json_value {
          label
          subMenu {
            label
            url
          }
        }
      }
      footerContactTitle
      footerContactContent {
        data {
          footerContactContent
        }
      }
      copyrightLinks {
        strapi_json_value {
          label
          url
        }
      }
    }
  }
`;
